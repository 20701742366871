import type { Context } from "@squared/context";
// Code generated by @squared/cli, DO NOT EDIT.
import { RPCContextClient } from "@squared/rpc-client";

export type LoginRequest = {
	email: string;
	password: string;
};

export type LoginResponse = {
	token: string;
	user: {
		avatarUrl: string | null;
		createdAt: Date;
		defaultWorkspaceId: string | null;
		email: string;
		githubId: string | null;
		githubUsername: string | null;
		googleId: string | null;
		id: string;
		lastLogin: Date;
		name: string;
		onBoarding: boolean;
		password: string | null;
		savedNotificationIds: string[];
		subscribedTasks: string[];
		username: string | null;
		verified: boolean;
	};
} | null;

export type GoogleLoginRequest = {
	avatarUrl?: string | null;
	email: string;
	name?: string;
	oauthId: string;
	username?: string;
};

export type GoogleLoginResponse = {
	token: string;
	user: {
		avatarUrl: string | null;
		createdAt: Date;
		defaultWorkspaceId: string | null;
		email: string;
		githubId: string | null;
		githubUsername: string | null;
		googleId: string | null;
		id: string;
		lastLogin: Date;
		name: string;
		onBoarding: boolean;
		password: string | null;
		savedNotificationIds: string[];
		subscribedTasks: string[];
		username: string | null;
		verified: boolean;
	};
} | null;

export type RegisterRequest = {
	email: string;
	inviteToken?: string;
	name: string;
	password: string;
	username: string;
};

export type RegisterResponse = {
	message?: string;
	user: {
		avatarUrl: string | null;
		createdAt: Date;
		defaultWorkspaceId: string | null;
		email: string;
		githubId: string | null;
		githubUsername: string | null;
		googleId: string | null;
		id: string;
		lastLogin: Date;
		name: string;
		onBoarding: boolean;
		password: string | null;
		savedNotificationIds: string[];
		subscribedTasks: string[];
		username: string | null;
		verified: boolean;
	} | null;
	variant?: "default" | "destructive" | null;
};

export type VerifyUserRequest = {
	token: string;
};

export type VerifyUserResponse = {
	avatarUrl: string | null;
	createdAt: Date;
	defaultWorkspaceId: string | null;
	email: string;
	githubId: string | null;
	githubUsername: string | null;
	googleId: string | null;
	id: string;
	lastLogin: Date;
	name: string;
	onBoarding: boolean;
	password: string | null;
	savedNotificationIds: string[];
	subscribedTasks: string[];
	username: string | null;
	verified: boolean;
} | null;

export type ResetPasswordEmailRequest = {
	email: string;
};

export type ResetPasswordRequest = {
	newPassword: string;
	token: string;
};

export type CheckTokenValidRequest = {
	token: string;
};

export type CheckTokenValidResponse = {
	email: string;
	message: string;
} | null;

/**
 * auth service
 */
export class AuthService extends RPCContextClient {
	constructor(baseUrl: string) {
		super(baseUrl, "auth");
	}

	/**
	 * login method
	 */
	login(ctx: Context, req: LoginRequest): Promise<LoginResponse> {
		return this.request(ctx, "login", req);
	}

	/**
	 * googleLogin method
	 */
	googleLogin(
		ctx: Context,
		req: GoogleLoginRequest,
	): Promise<GoogleLoginResponse> {
		return this.request(ctx, "googleLogin", req);
	}

	/**
	 * register method
	 */
	register(ctx: Context, req: RegisterRequest): Promise<RegisterResponse> {
		return this.request(ctx, "register", req);
	}

	/**
	 * verifyUser method
	 */
	verifyUser(
		ctx: Context,
		req: VerifyUserRequest,
	): Promise<VerifyUserResponse> {
		return this.request(ctx, "verifyUser", req);
	}

	/**
	 * resetPasswordEmail method
	 */
	resetPasswordEmail(
		ctx: Context,
		req: ResetPasswordEmailRequest,
	): Promise<void> {
		return this.request(ctx, "resetPasswordEmail", req);
	}

	/**
	 * resetPassword method
	 */
	resetPassword(ctx: Context, req: ResetPasswordRequest): Promise<void> {
		return this.request(ctx, "resetPassword", req);
	}

	/**
	 * checkTokenValid method
	 */
	checkTokenValid(
		ctx: Context,
		req: CheckTokenValidRequest,
	): Promise<CheckTokenValidResponse> {
		return this.request(ctx, "checkTokenValid", req);
	}
}
