import type { Context } from "@squared/context";
// Code generated by @squared/cli, DO NOT EDIT.
import { RPCContextClient } from "@squared/rpc-client";

export type OnBoardUserRequest = {
	userId: string;
};

export type OnBoardUserResponse = {
	avatarUrl: string | null;
	createdAt: Date;
	defaultWorkspaceId: string | null;
	email: string;
	githubId: string | null;
	githubUsername: string | null;
	googleId: string | null;
	id: string;
	lastLogin: Date;
	name: string;
	onBoarding: boolean;
	password: string | null;
	savedNotificationIds: string[];
	subscribedTasks: string[];
	username: string | null;
	verified: boolean;
};

export type UpdateUserRequest = {
	name: string;
	userId: string;
	username?: string;
};

export type UpdateUserResponse = {
	avatarUrl: string | null;
	createdAt: Date;
	defaultWorkspaceId: string | null;
	email: string;
	githubId: string | null;
	githubUsername: string | null;
	googleId: string | null;
	id: string;
	lastLogin: Date;
	name: string;
	onBoarding: boolean;
	password: string | null;
	savedNotificationIds: string[];
	subscribedTasks: string[];
	username: string | null;
	verified: boolean;
};

export type UpdateUserAvatarRequest = {
	avatarUrl: string;
	userId: string;
};

export type UpdateUserAvatarResponse = {
	avatarUrl: string | null;
	createdAt: Date;
	defaultWorkspaceId: string | null;
	email: string;
	githubId: string | null;
	githubUsername: string | null;
	googleId: string | null;
	id: string;
	lastLogin: Date;
	name: string;
	onBoarding: boolean;
	password: string | null;
	savedNotificationIds: string[];
	subscribedTasks: string[];
	username: string | null;
	verified: boolean;
};

export type UpdateUserNotificationsRequest = {
	notificationIds: string[];
	userId: string;
};

export type UpdateUserNotificationsResponse = {
	avatarUrl: string | null;
	createdAt: Date;
	defaultWorkspaceId: string | null;
	email: string;
	githubId: string | null;
	githubUsername: string | null;
	googleId: string | null;
	id: string;
	lastLogin: Date;
	name: string;
	onBoarding: boolean;
	password: string | null;
	savedNotificationIds: string[];
	subscribedTasks: string[];
	username: string | null;
	verified: boolean;
};

export type GetUserRequest = {
	userId: string;
};

export type GetUserResponse = {
	avatarUrl: string | null;
	createdAt: Date;
	defaultWorkspaceId: string | null;
	email: string;
	githubId: string | null;
	githubUsername: string | null;
	googleId: string | null;
	id: string;
	lastLogin: Date;
	name: string;
	onBoarding: boolean;
	password: string | null;
	savedNotificationIds: string[];
	subscribedTasks: string[];
	username: string | null;
	verified: boolean;
} | null;

export type GetWorkspaceUsersRequest = {
	workspaceId: string;
};

export type GetWorkspaceUsersResponse = {
	avatarUrl: string | null;
	createdAt: Date;
	defaultWorkspaceId: string | null;
	email: string;
	githubId: string | null;
	githubUsername: string | null;
	googleId: string | null;
	id: string;
	lastLogin: Date;
	name: string;
	onBoarding: boolean;
	password: string | null;
	savedNotificationIds: string[];
	subscribedTasks: string[];
	username: string | null;
	verified: boolean;
}[];

export type GetTeamUsersRequest = {
	teamId: string;
};

export type GetTeamUsersResponse = {
	avatarUrl: string | null;
	createdAt: Date;
	defaultWorkspaceId: string | null;
	email: string;
	githubId: string | null;
	githubUsername: string | null;
	googleId: string | null;
	id: string;
	lastLogin: Date;
	name: string;
	onBoarding: boolean;
	password: string | null;
	savedNotificationIds: string[];
	subscribedTasks: string[];
	username: string | null;
	verified: boolean;
}[];

export type GetUserAvatarsRequest = {
	workspaceId: string;
};

export type GetUserAvatarsResponse = {
	avatarUrl: string | null;
	id: string;
	name: string;
}[];

export type GetUserRepositoriesRequest = {
	userId: string;
};

export type GetUserRepositoriesResponse = string[];

export type GetUserTeamsRequest = {
	userId: string;
};

export type GetUserTeamsResponse = {
	cooldownDuration: number;
	effort: "LINEAR" | "FIBONACCI" | "EXPONENTIAL";
	id: string;
	identifier: string;
	name: string | null;
	sprintDuration: number;
	sprintStartDate: Date;
	sprintsEnabled: boolean;
	tasksPerSprint: number;
	workspaceId: string;
}[];

/**
 * user service
 */
export class UserService extends RPCContextClient {
	constructor(baseUrl: string) {
		super(baseUrl, "user");
	}

	/**
	 * onBoardUser method
	 */
	onBoardUser(
		ctx: Context,
		req: OnBoardUserRequest,
	): Promise<OnBoardUserResponse> {
		return this.request(ctx, "onBoardUser", req);
	}

	/**
	 * updateUser method
	 */
	updateUser(
		ctx: Context,
		req: UpdateUserRequest,
	): Promise<UpdateUserResponse> {
		return this.request(ctx, "updateUser", req);
	}

	/**
	 * updateUserAvatar method
	 */
	updateUserAvatar(
		ctx: Context,
		req: UpdateUserAvatarRequest,
	): Promise<UpdateUserAvatarResponse> {
		return this.request(ctx, "updateUserAvatar", req);
	}

	/**
	 * updateUserNotifications method
	 */
	updateUserNotifications(
		ctx: Context,
		req: UpdateUserNotificationsRequest,
	): Promise<UpdateUserNotificationsResponse> {
		return this.request(ctx, "updateUserNotifications", req);
	}

	/**
	 * getUser method
	 */
	getUser(ctx: Context, req: GetUserRequest): Promise<GetUserResponse> {
		return this.request(ctx, "getUser", req);
	}

	/**
	 * getWorkspaceUsers method
	 */
	getWorkspaceUsers(
		ctx: Context,
		req: GetWorkspaceUsersRequest,
	): Promise<GetWorkspaceUsersResponse> {
		return this.request(ctx, "getWorkspaceUsers", req);
	}

	/**
	 * getTeamUsers method
	 */
	getTeamUsers(
		ctx: Context,
		req: GetTeamUsersRequest,
	): Promise<GetTeamUsersResponse> {
		return this.request(ctx, "getTeamUsers", req);
	}

	/**
	 * getUserAvatars method
	 */
	getUserAvatars(
		ctx: Context,
		req: GetUserAvatarsRequest,
	): Promise<GetUserAvatarsResponse> {
		return this.request(ctx, "getUserAvatars", req);
	}

	/**
	 * getUserRepositories method
	 */
	getUserRepositories(
		ctx: Context,
		req: GetUserRepositoriesRequest,
	): Promise<GetUserRepositoriesResponse> {
		return this.request(ctx, "getUserRepositories", req);
	}

	/**
	 * getUserTeams method
	 */
	getUserTeams(
		ctx: Context,
		req: GetUserTeamsRequest,
	): Promise<GetUserTeamsResponse> {
		return this.request(ctx, "getUserTeams", req);
	}
}
