import type { Context } from "@squared/context";
// Code generated by @squared/cli, DO NOT EDIT.
import { RPCContextClient } from "@squared/rpc-client";

export type AddCommentRequest = {
	comment: {
		authorId: string;
		comment: string;
		date: Date;
		taskId: string;
	};
};

export type AddCommentResponse = {
	authorId: string;
	comment: string;
	date: Date;
	id: string;
	taskId: string;
}[];

export type DeleteCommentRequest = {
	commentId: string;
};

export type GetTaskCommentsRequest = {
	taskId: string;
};

export type GetTaskCommentsResponse = {
	authorId: string;
	comment: string;
	date: Date;
	id: string;
	taskId: string;
}[];

/**
 * comment service
 */
export class CommentService extends RPCContextClient {
	constructor(baseUrl: string) {
		super(baseUrl, "comment");
	}

	/**
	 * addComment method
	 */
	addComment(
		ctx: Context,
		req: AddCommentRequest,
	): Promise<AddCommentResponse> {
		return this.request(ctx, "addComment", req);
	}

	/**
	 * deleteComment method
	 */
	deleteComment(ctx: Context, req: DeleteCommentRequest): Promise<void> {
		return this.request(ctx, "deleteComment", req);
	}

	/**
	 * getTaskComments method
	 */
	getTaskComments(
		ctx: Context,
		req: GetTaskCommentsRequest,
	): Promise<GetTaskCommentsResponse> {
		return this.request(ctx, "getTaskComments", req);
	}
}
