import type { Context } from "@squared/context";
// Code generated by @squared/cli, DO NOT EDIT.
import { RPCContextClient } from "@squared/rpc-client";

export type CreateTaskRequest = {
	authorId: string;
	description?: string;
	dueDate: Date | null;
	effortEstimate: number | null;
	labels?: string[];
	parentId?: string | null;
	priority?: "noPriority" | "urgent" | "high" | "medium" | "low";
	sprintId?: string | null;
	status?:
		| "backlog"
		| "todo"
		| "inProgress"
		| "inReview"
		| "done"
		| "canceled"
		| "archived";
	teamId: string;
	title: string;
};

export type CreateTaskResponse = {
	assigneeId: string | null;
	authorId: string;
	dateCreated: Date;
	deleted: boolean;
	description: string | null;
	dueDate: Date | null;
	effortEstimate: number | null;
	id: string;
	identifier: string;
	labels: string[];
	order: number;
	parentId: string | null;
	priority: "noPriority" | "urgent" | "high" | "medium" | "low";
	sprintId: string | null;
	status:
		| "backlog"
		| "todo"
		| "inProgress"
		| "inReview"
		| "done"
		| "canceled"
		| "archived";
	teamId: string;
	title: string;
	updatedAt: Date;
	workspaceId: string;
};

export type UpdateTaskRequest = {
	updaterId: string | null;
	assigneeId?: string | null;
	description?: string;
	dueDate?: Date | null;
	effortEstimate?: number;
	id: string;
	labels?: string[];
	parentId?: string | null;
	priority?: "noPriority" | "urgent" | "high" | "medium" | "low";
	sprintId?: string | null;
	status?:
		| "backlog"
		| "todo"
		| "inProgress"
		| "inReview"
		| "done"
		| "canceled"
		| "archived";
	title?: string;
};

export type UpdateTaskResponse = {
	assigneeId: string | null;
	authorId: string;
	dateCreated: Date;
	deleted: boolean;
	description: string | null;
	dueDate: Date | null;
	effortEstimate: number | null;
	id: string;
	identifier: string;
	labels: string[];
	order: number;
	parentId: string | null;
	priority: "noPriority" | "urgent" | "high" | "medium" | "low";
	sprintId: string | null;
	status:
		| "backlog"
		| "todo"
		| "inProgress"
		| "inReview"
		| "done"
		| "canceled"
		| "archived";
	teamId: string;
	title: string;
	updatedAt: Date;
	workspaceId: string;
};

export type DeleteTaskRequest = {
	taskId: string;
};

export type GetTaskRequest = {
	taskId: string;
};

export type GetTaskResponse = {
	assigneeId: string | null;
	authorId: string;
	dateCreated: Date;
	deleted: boolean;
	description: string | null;
	dueDate: Date | null;
	effortEstimate: number | null;
	id: string;
	identifier: string;
	labels: string[];
	order: number;
	parentId: string | null;
	priority: "noPriority" | "urgent" | "high" | "medium" | "low";
	sprintId: string | null;
	status:
		| "backlog"
		| "todo"
		| "inProgress"
		| "inReview"
		| "done"
		| "canceled"
		| "archived";
	teamId: string;
	title: string;
	updatedAt: Date;
	workspaceId: string;
};

export type GetTaskByIdentifierRequest = {
	identifier: string;
	workspaceId: string;
};

export type GetTaskByIdentifierResponse = {
	assigneeId: string | null;
	authorId: string;
	dateCreated: Date;
	deleted: boolean;
	description: string | null;
	dueDate: Date | null;
	effortEstimate: number | null;
	id: string;
	identifier: string;
	labels: string[];
	order: number;
	parentId: string | null;
	priority: "noPriority" | "urgent" | "high" | "medium" | "low";
	sprintId: string | null;
	status:
		| "backlog"
		| "todo"
		| "inProgress"
		| "inReview"
		| "done"
		| "canceled"
		| "archived";
	teamId: string;
	title: string;
	updatedAt: Date;
	workspaceId: string;
};

export type GetTeamTasksRequest = {
	teamId: string;
};

export type GetTeamTasksResponse = {
	assigneeId: string | null;
	authorId: string;
	dateCreated: Date;
	deleted: boolean;
	description: string | null;
	dueDate: Date | null;
	effortEstimate: number | null;
	id: string;
	identifier: string;
	labels: string[];
	order: number;
	parentId: string | null;
	priority: "noPriority" | "urgent" | "high" | "medium" | "low";
	sprintId: string | null;
	status:
		| "backlog"
		| "todo"
		| "inProgress"
		| "inReview"
		| "done"
		| "canceled"
		| "archived";
	teamId: string;
	title: string;
	updatedAt: Date;
	workspaceId: string;
}[];

export type AddActiveSprintTasksRequest = {
	sprintId: string;
};

export type AddActiveSprintTasksResponse = number;

export type AddSprintTasksRequest = {
	sprintId: string;
	taskIds: string[];
};

export type AddSprintTasksResponse = number;

export type ReorderSubtasksRequest = {
	newOrder: string[];
	parentId: string;
};

export type ReorderSubtasksResponse = {
	assigneeId: string | null;
	authorId: string;
	dateCreated: Date;
	deleted: boolean;
	description: string | null;
	dueDate: Date | null;
	effortEstimate: number | null;
	id: string;
	identifier: string;
	labels: string[];
	order: number;
	parentId: string | null;
	priority: "noPriority" | "urgent" | "high" | "medium" | "low";
	sprintId: string | null;
	status:
		| "backlog"
		| "todo"
		| "inProgress"
		| "inReview"
		| "done"
		| "canceled"
		| "archived";
	teamId: string;
	title: string;
	updatedAt: Date;
	workspaceId: string;
}[];

export type GetSubtasksRequest = {
	parentId: string;
};

export type GetSubtasksResponse = {
	assigneeId: string | null;
	authorId: string;
	dateCreated: Date;
	deleted: boolean;
	description: string | null;
	dueDate: Date | null;
	effortEstimate: number | null;
	id: string;
	identifier: string;
	labels: string[];
	order: number;
	parentId: string | null;
	priority: "noPriority" | "urgent" | "high" | "medium" | "low";
	sprintId: string | null;
	status:
		| "backlog"
		| "todo"
		| "inProgress"
		| "inReview"
		| "done"
		| "canceled"
		| "archived";
	teamId: string;
	title: string;
	updatedAt: Date;
	workspaceId: string;
}[];

/**
 * task service
 */
export class TaskService extends RPCContextClient {
	constructor(baseUrl: string) {
		super(baseUrl, "task");
	}

	/**
	 * createTask method
	 */
	createTask(
		ctx: Context,
		req: CreateTaskRequest,
	): Promise<CreateTaskResponse> {
		return this.request(ctx, "createTask", req);
	}

	/**
	 * updateTask method
	 */
	updateTask(
		ctx: Context,
		req: UpdateTaskRequest,
	): Promise<UpdateTaskResponse> {
		return this.request(ctx, "updateTask", req);
	}

	/**
	 * deleteTask method
	 */
	deleteTask(ctx: Context, req: DeleteTaskRequest): Promise<void> {
		return this.request(ctx, "deleteTask", req);
	}

	/**
	 * getTask method
	 */
	getTask(ctx: Context, req: GetTaskRequest): Promise<GetTaskResponse> {
		return this.request(ctx, "getTask", req);
	}

	/**
	 * getTaskByIdentifier method
	 */
	getTaskByIdentifier(
		ctx: Context,
		req: GetTaskByIdentifierRequest,
	): Promise<GetTaskByIdentifierResponse> {
		return this.request(ctx, "getTaskByIdentifier", req);
	}

	/**
	 * getTeamTasks method
	 */
	getTeamTasks(
		ctx: Context,
		req: GetTeamTasksRequest,
	): Promise<GetTeamTasksResponse> {
		return this.request(ctx, "getTeamTasks", req);
	}

	/**
	 * addActiveSprintTasks method
	 */
	addActiveSprintTasks(
		ctx: Context,
		req: AddActiveSprintTasksRequest,
	): Promise<AddActiveSprintTasksResponse> {
		return this.request(ctx, "addActiveSprintTasks", req);
	}

	/**
	 * addSprintTasks method
	 */
	addSprintTasks(
		ctx: Context,
		req: AddSprintTasksRequest,
	): Promise<AddSprintTasksResponse> {
		return this.request(ctx, "addSprintTasks", req);
	}

	/**
	 * reorderSubtasks method
	 */
	reorderSubtasks(
		ctx: Context,
		req: ReorderSubtasksRequest,
	): Promise<ReorderSubtasksResponse> {
		return this.request(ctx, "reorderSubtasks", req);
	}

	/**
	 * getSubtasks method
	 */
	getSubtasks(
		ctx: Context,
		req: GetSubtasksRequest,
	): Promise<GetSubtasksResponse> {
		return this.request(ctx, "getSubtasks", req);
	}
}
