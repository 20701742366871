import type { Context } from "@squared/context";
// Code generated by @squared/cli, DO NOT EDIT.
import { RPCContextClient } from "@squared/rpc-client";

export type GetSprintsRequest = {
	teamId: string;
};

export type GetSprintsResponse = {
	createdAt: Date;
	description: string | null;
	endDate: Date;
	id: string;
	name: string;
	startDate: Date;
	status: "PLANNED" | "ACTIVE" | "COMPLETED";
	teamId: string;
	updatedAt: Date;
}[];

export type UpdateSprintRequest = {
	sprintData: {
		description: string | null;
		endDate: Date;
		name: string;
		startDate: Date;
	};
	sprintId: string;
};

export type UpdateSprintResponse = {
	createdAt: Date;
	description: string | null;
	endDate: Date;
	id: string;
	name: string;
	startDate: Date;
	status: "PLANNED" | "ACTIVE" | "COMPLETED";
	teamId: string;
	updatedAt: Date;
};

export type InitializeSprintsRequest = {
	teamId: string;
};

export type InitializeSprintsResponse = number;

export type StartNextSprintRequest = {
	sprintData?: {
		description?: string;
		name: string;
	};
	teamId: string;
};

export type StartNextSprintResponse =
	| {
			message: string;
			status: number;
			variant: "destructive";
	  }
	| {
			data: {
				createdAt: Date;
				description: string | null;
				endDate: Date;
				id: string;
				name: string;
				startDate: Date;
				status: "PLANNED" | "ACTIVE" | "COMPLETED";
				teamId: string;
				updatedAt: Date;
			};
			message: string;
			variant: "default";
	  };

export type GetSprintTasksRequest = {
	sprintId: string;
};

export type GetSprintTasksResponse = {
	assigneeId: string | null;
	authorId: string;
	dateCreated: Date;
	deleted: boolean;
	description: string | null;
	dueDate: Date | null;
	effortEstimate: number | null;
	id: string;
	identifier: string;
	labels: string[];
	order: number;
	parentId: string | null;
	priority: "noPriority" | "urgent" | "high" | "medium" | "low";
	sprintId: string | null;
	status:
		| "backlog"
		| "todo"
		| "inProgress"
		| "inReview"
		| "done"
		| "canceled"
		| "archived";
	teamId: string;
	title: string;
	updatedAt: Date;
	workspaceId: string;
}[];

export type EndSprintRequest = {
	sprintId: string;
};

export type EndSprintResponse = {
	createdAt: Date;
	description: string | null;
	endDate: Date;
	id: string;
	name: string;
	startDate: Date;
	status: "PLANNED" | "ACTIVE" | "COMPLETED";
	teamId: string;
	updatedAt: Date;
};

export type AddRetrospectiveItemRequest = {
	authorId: string;
	content: string;
	sprintId: string;
	type: "wentWell" | "toImprove" | "actionItems";
};

export type AddRetrospectiveItemResponse = {
	authorId: string;
	content: string;
	id: string;
	likes: string[];
	type: "toImprove" | "wentWell" | "actionItems";
};

export type UpdateRetrospectiveItemRequest = {
	content?: string;
	retrospectiveItemId: string;
	sprintId: string;
	type?: "wentWell" | "toImprove" | "actionItems";
};

export type UpdateRetrospectiveItemResponse = {
	authorId: string;
	content: string;
	id: string;
	likes: string[];
	type: "toImprove" | "wentWell" | "actionItems";
};

export type LikeRetrospectiveItemRequest = {
	retrospectiveItemId: string;
	userId: string;
};

export type LikeRetrospectiveItemResponse = {
	authorId: string;
	content: string;
	id: string;
	likes: string[];
	type: "toImprove" | "wentWell" | "actionItems";
};

export type GetRetrospectiveItemsRequest = {
	sprintId: string;
};

export type GetRetrospectiveItemsResponse = {
	actionItems: {
		authorId: string;
		content: string;
		id: string;
		likes: string[];
		type: "toImprove" | "wentWell" | "actionItems";
	}[];
	toImprove: {
		authorId: string;
		content: string;
		id: string;
		likes: string[];
		type: "toImprove" | "wentWell" | "actionItems";
	}[];
	wentWell: {
		authorId: string;
		content: string;
		id: string;
		likes: string[];
		type: "toImprove" | "wentWell" | "actionItems";
	}[];
};

/**
 * sprint service
 */
export class SprintService extends RPCContextClient {
	constructor(baseUrl: string) {
		super(baseUrl, "sprint");
	}

	/**
	 * getSprints method
	 */
	getSprints(
		ctx: Context,
		req: GetSprintsRequest,
	): Promise<GetSprintsResponse> {
		return this.request(ctx, "getSprints", req);
	}

	/**
	 * updateSprint method
	 */
	updateSprint(
		ctx: Context,
		req: UpdateSprintRequest,
	): Promise<UpdateSprintResponse> {
		return this.request(ctx, "updateSprint", req);
	}

	/**
	 * initializeSprints method
	 */
	initializeSprints(
		ctx: Context,
		req: InitializeSprintsRequest,
	): Promise<InitializeSprintsResponse> {
		return this.request(ctx, "initializeSprints", req);
	}

	/**
	 * startNextSprint method
	 */
	startNextSprint(
		ctx: Context,
		req: StartNextSprintRequest,
	): Promise<StartNextSprintResponse> {
		return this.request(ctx, "startNextSprint", req);
	}

	/**
	 * getSprintTasks method
	 */
	getSprintTasks(
		ctx: Context,
		req: GetSprintTasksRequest,
	): Promise<GetSprintTasksResponse> {
		return this.request(ctx, "getSprintTasks", req);
	}

	/**
	 * endSprint method
	 */
	endSprint(ctx: Context, req: EndSprintRequest): Promise<EndSprintResponse> {
		return this.request(ctx, "endSprint", req);
	}

	/**
	 * addRetrospectiveItem method
	 */
	addRetrospectiveItem(
		ctx: Context,
		req: AddRetrospectiveItemRequest,
	): Promise<AddRetrospectiveItemResponse> {
		return this.request(ctx, "addRetrospectiveItem", req);
	}

	/**
	 * updateRetrospectiveItem method
	 */
	updateRetrospectiveItem(
		ctx: Context,
		req: UpdateRetrospectiveItemRequest,
	): Promise<UpdateRetrospectiveItemResponse> {
		return this.request(ctx, "updateRetrospectiveItem", req);
	}

	/**
	 * likeRetrospectiveItem method
	 */
	likeRetrospectiveItem(
		ctx: Context,
		req: LikeRetrospectiveItemRequest,
	): Promise<LikeRetrospectiveItemResponse> {
		return this.request(ctx, "likeRetrospectiveItem", req);
	}

	/**
	 * getRetrospectiveItems method
	 */
	getRetrospectiveItems(
		ctx: Context,
		req: GetRetrospectiveItemsRequest,
	): Promise<GetRetrospectiveItemsResponse> {
		return this.request(ctx, "getRetrospectiveItems", req);
	}
}
