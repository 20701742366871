import type { Context } from "@squared/context";
// Code generated by @squared/cli, DO NOT EDIT.
import { RPCContextClient } from "@squared/rpc-client";

export type GetTaskEventsRequest = {
	taskId: string;
};

export type GetTaskEventsResponse =
	| unknown
	| {
			authorName: string | null;
			branchId: string;
			id: string;
			message: string;
			owner: string | null;
			repoName: string | null;
			taskId: string | null;
			timestamp: Date;
			url: string;
	  }[];

export type GetNotificationsRequest = {
	userId: string;
};

export type GetNotificationsResponse = {
	Task: {
		assigneeId: string | null;
		assigneeName: string | null;
		authorId: string;
		dateCreated: Date;
		deleted: boolean;
		description: string | null;
		dueDate: Date | null;
		effortEstimate: number | null;
		id: string;
		identifier: string;
		labels: string[];
		order: number;
		parentId: string | null;
		priority: "noPriority" | "urgent" | "high" | "medium" | "low";
		sprintId: string | null;
		status:
			| "backlog"
			| "todo"
			| "inProgress"
			| "inReview"
			| "done"
			| "canceled"
			| "archived";
		teamId: string;
		title: string;
		updatedAt: Date;
		workspaceId: string;
	};
	Workspace: {
		admins: string[];
		avatarUrl: string | null;
		companySize: number | null;
		id: string;
		name: string;
		tasksCreated: number;
		universalTokenLinkId: string | null;
		url: string;
	};
	createdAt: Date;
	description: string | null;
	dismissed: boolean;
	id: string;
	read: boolean;
	saved: boolean;
	taskId: string;
	type: "ASSIGNED" | "PARTICIPATING" | "MENTIONED" | "CREATED";
	updatedAt: Date;
	userId: string;
	workspaceId: string;
}[];

export type CreateLogEventRequest = {
	authorId: string;
	changes: unknown;
	taskId: string;
};

export type CreateLogEventResponse = {
	authorId: string;
	createdAt: Date;
	id: string;
	message: string;
	taskId: string;
};

export type CreateNotificationRequest = {
	description: string;
	taskId: string;
	type: "ASSIGNED" | "PARTICIPATING" | "MENTIONED" | "CREATED";
	userId: string;
	workspaceId: string;
};

export type CreateNotificationResponse = {
	createdAt: Date;
	description: string | null;
	dismissed: boolean;
	id: string;
	read: boolean;
	saved: boolean;
	taskId: string;
	type: "ASSIGNED" | "PARTICIPATING" | "MENTIONED" | "CREATED";
	updatedAt: Date;
	userId: string;
	workspaceId: string;
};

export type ToggleNotificationRequest = {
	dismissed?: boolean;
	notificationIds: string[];
	read?: boolean;
};

export type ToggleNotificationResponse = {
	createdAt: Date;
	description: string | null;
	dismissed: boolean;
	id: string;
	read: boolean;
	saved: boolean;
	taskId: string;
	type: "ASSIGNED" | "PARTICIPATING" | "MENTIONED" | "CREATED";
	updatedAt: Date;
	userId: string;
	workspaceId: string;
}[];

export type DeleteNotificationRequest = {
	notificationIds: string[];
};

/**
 * event service
 */
export class EventService extends RPCContextClient {
	constructor(baseUrl: string) {
		super(baseUrl, "event");
	}

	/**
	 * getTaskEvents method
	 */
	getTaskEvents(
		ctx: Context,
		req: GetTaskEventsRequest,
	): Promise<GetTaskEventsResponse> {
		return this.request(ctx, "getTaskEvents", req);
	}

	/**
	 * getNotifications method
	 */
	getNotifications(
		ctx: Context,
		req: GetNotificationsRequest,
	): Promise<GetNotificationsResponse> {
		return this.request(ctx, "getNotifications", req);
	}

	/**
	 * createLogEvent method
	 */
	createLogEvent(
		ctx: Context,
		req: CreateLogEventRequest,
	): Promise<CreateLogEventResponse> {
		return this.request(ctx, "createLogEvent", req);
	}

	/**
	 * createNotification method
	 */
	createNotification(
		ctx: Context,
		req: CreateNotificationRequest,
	): Promise<CreateNotificationResponse> {
		return this.request(ctx, "createNotification", req);
	}

	/**
	 * toggleNotification method
	 */
	toggleNotification(
		ctx: Context,
		req: ToggleNotificationRequest,
	): Promise<ToggleNotificationResponse> {
		return this.request(ctx, "toggleNotification", req);
	}

	/**
	 * deleteNotification method
	 */
	deleteNotification(
		ctx: Context,
		req: DeleteNotificationRequest,
	): Promise<void> {
		return this.request(ctx, "deleteNotification", req);
	}
}
