import type { Context } from "@squared/context";
// Code generated by @squared/cli, DO NOT EDIT.
import { RPCContextClient } from "@squared/rpc-client";

export type CreateFilterRequest = {
	authorId: string;
	description: string | null;
	filter: {
		field:
			| "id"
			| "title"
			| "description"
			| "status"
			| "sprintId"
			| "teamId"
			| "updatedAt"
			| "authorId"
			| "identifier"
			| "dueDate"
			| "effortEstimate"
			| "priority"
			| "dateCreated"
			| "assigneeId"
			| "labels"
			| "workspaceId"
			| "parentId"
			| "deleted"
			| "order";
		operator:
			| "equals"
			| "contains"
			| "greaterThan"
			| "lessThan"
			| "arrayIncludesAll"
			| "arrayIncludesAny";
		value:
			| string
			| number
			| Date
			| boolean
			| null
			| (string | null)[]
			| string[];
	}[];
	name: string;
	teamId: string;
};

export type CreateFilterResponse = {
	authorId: string;
	description: string | null;
	filter: {
		field:
			| "id"
			| "title"
			| "description"
			| "status"
			| "sprintId"
			| "teamId"
			| "updatedAt"
			| "authorId"
			| "identifier"
			| "dueDate"
			| "effortEstimate"
			| "priority"
			| "dateCreated"
			| "assigneeId"
			| "labels"
			| "workspaceId"
			| "parentId"
			| "deleted"
			| "order";
		operator:
			| "equals"
			| "contains"
			| "greaterThan"
			| "lessThan"
			| "arrayIncludesAll"
			| "arrayIncludesAny";
		value:
			| string
			| number
			| Date
			| boolean
			| null
			| (string | null)[]
			| string[];
	}[];
	id: string;
	name: string;
	teamId: string | null;
	type: "TEAM" | "WORKSPACE";
	workspaceId: string | null;
};

export type GetFiltersRequest = {
	teamId: string;
};

export type GetFiltersResponse = {
	authorId: string;
	description: string | null;
	filter: {
		field:
			| "id"
			| "title"
			| "description"
			| "status"
			| "sprintId"
			| "teamId"
			| "updatedAt"
			| "authorId"
			| "identifier"
			| "dueDate"
			| "effortEstimate"
			| "priority"
			| "dateCreated"
			| "assigneeId"
			| "labels"
			| "workspaceId"
			| "parentId"
			| "deleted"
			| "order";
		operator:
			| "equals"
			| "contains"
			| "greaterThan"
			| "lessThan"
			| "arrayIncludesAll"
			| "arrayIncludesAny";
		value:
			| string
			| number
			| Date
			| boolean
			| null
			| (string | null)[]
			| string[];
	}[];
	id: string;
	name: string;
	teamId: string | null;
	type: "TEAM" | "WORKSPACE";
	workspaceId: string | null;
}[];

export type UpdateFilterRequest = {
	filterId: string;
	filters: {
		description: string | null;
		filter: {
			field:
				| "id"
				| "title"
				| "description"
				| "status"
				| "sprintId"
				| "teamId"
				| "updatedAt"
				| "authorId"
				| "identifier"
				| "dueDate"
				| "effortEstimate"
				| "priority"
				| "dateCreated"
				| "assigneeId"
				| "labels"
				| "workspaceId"
				| "parentId"
				| "deleted"
				| "order";
			operator:
				| "equals"
				| "contains"
				| "greaterThan"
				| "lessThan"
				| "arrayIncludesAll"
				| "arrayIncludesAny";
			value:
				| string
				| number
				| Date
				| boolean
				| null
				| (string | null)[]
				| string[];
		}[];
		name?: string;
	};
};

export type UpdateFilterResponse = {
	authorId: string;
	description: string | null;
	filter: {
		field:
			| "id"
			| "title"
			| "description"
			| "status"
			| "sprintId"
			| "teamId"
			| "updatedAt"
			| "authorId"
			| "identifier"
			| "dueDate"
			| "effortEstimate"
			| "priority"
			| "dateCreated"
			| "assigneeId"
			| "labels"
			| "workspaceId"
			| "parentId"
			| "deleted"
			| "order";
		operator:
			| "equals"
			| "contains"
			| "greaterThan"
			| "lessThan"
			| "arrayIncludesAll"
			| "arrayIncludesAny";
		value:
			| string
			| number
			| Date
			| boolean
			| null
			| (string | null)[]
			| string[];
	}[];
	id: string;
	name: string;
	teamId: string | null;
	type: "TEAM" | "WORKSPACE";
	workspaceId: string | null;
};

export type DeleteFilterRequest = {
	filterId: string;
};

/**
 * filter service
 */
export class FilterService extends RPCContextClient {
	constructor(baseUrl: string) {
		super(baseUrl, "filter");
	}

	/**
	 * createFilter method
	 */
	createFilter(
		ctx: Context,
		req: CreateFilterRequest,
	): Promise<CreateFilterResponse> {
		return this.request(ctx, "createFilter", req);
	}

	/**
	 * getFilters method
	 */
	getFilters(
		ctx: Context,
		req: GetFiltersRequest,
	): Promise<GetFiltersResponse> {
		return this.request(ctx, "getFilters", req);
	}

	/**
	 * updateFilter method
	 */
	updateFilter(
		ctx: Context,
		req: UpdateFilterRequest,
	): Promise<UpdateFilterResponse> {
		return this.request(ctx, "updateFilter", req);
	}

	/**
	 * deleteFilter method
	 */
	deleteFilter(ctx: Context, req: DeleteFilterRequest): Promise<void> {
		return this.request(ctx, "deleteFilter", req);
	}
}
